.about {
    min-height: 100vh;
    position: relative;
}

.about .line-styling{
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  height: 50px;
  width: 85%;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.about .line-styling .style-circle{
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.about .line-styling .style-line{
  width: 95%;
  height: 5px;
  border-radius: 10px;
}

.about-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding-top: 8rem;
    height: fit-content;
}

.about-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    flex: 0.5;
    width: 100%;
    height: fit-content;
}

.about-description >h2 {
    margin-bottom: 40px;
    font-size: 3.5rem;
    font-family: var(--primaryFont);
}

.about-description > p {
    font-size: 1.25rem;
    color: #e0d9d9;
    font-family: var(--primaryFont);
}

.about-img {
    margin-top: 40px;
    pointer-events: none;
}

.about-img > img {
    width: 380px;
    flex: 0.5;
}

@media (min-width: 992px) and (max-width: 1380px) {

    .about-description > h2 {
      font-size: 3.3rem;
      margin-bottom: 30px;
    }
    .about-description > p {
      font-size: 1.15rem;
    }

    .about-img>img {
        width: 300px;
    } 
}

@media screen and (max-width: 992px){

  .about .line-styling{
    top: 20px;
  }

    .about-body {
        padding-top: 2rem;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-evenly;
    }

    .about-description{
        flex: 0.6;
        box-sizing: border-box;
        /* padding: 5% 10%; */
        padding: 20px;

    }

    .about-description > h2 {
        font-size: 3rem;
        margin-bottom: 20px;
    }

    .about-description > p {
        font-size: 1.1rem;
    }

    .about-img > img {
        width: 250px;
        flex: 0.4;
    }

}

@media screen and (max-width: 800px){
  .about .line-styling .style-circle{
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
  .about-description > h2{
    align-self: center;
  }

}

@media screen and (max-width: 600px){

  .about .line-styling .style-circle{
    width: 5px;
    height: 5px;
    border-radius: 50%;
  }
  .about .line-styling .style-line{
    width: 93%;
    height: 3px;
    border-radius: 10px;
  }

  .about-description > h2 {
    font-size: 2.5rem;
  }

    .about-description > p{
      font-size: 0.95rem;
    }

    .about-img > img {
        width: 200px;
    }
}






/* ... (previous CSS remains the same) ... */

.companies-worked-with {
  margin-top: 4rem;
  text-align: center;
  width: 100%;
}

.companies-worked-with h3 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  font-family: var(--primaryFont);
  color: #fca311; /* Assuming this is the color you're using for headers */
}

.company-logos {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
}

.company-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px; /* Set a fixed width for each logo container */
}

.company-logo img {
  width: 100%;
  height: 100px; /* Set a fixed height for all logos */
  object-fit: contain; /* This will ensure the logo fits within the container without distortion */
  background-color: white; /* Add a white background to ensure visibility of logos */
  padding: 10px;
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.company-logo img:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.company-logo p {
  margin-top: 1rem;
  font-size: 1rem;
  color: #e0d9d9;
  font-family: var(--primaryFont);
}

/* Responsive adjustments */
@media screen and (max-width: 1200px) {
  .company-logo {
      width: 180px;
  }
  .company-logo img {
      height: 90px;
  }
}

@media screen and (max-width: 992px) {
  .company-logo {
      width: 160px;
  }
  .company-logo img {
      height: 80px;
  }
  .companies-worked-with h3 {
      font-size: 2.2rem;
  }
}

@media screen and (max-width: 768px) {
  .company-logo {
      width: 140px;
  }
  .company-logo img {
      height: 70px;
  }
  .companies-worked-with h3 {
      font-size: 2rem;
  }
}

@media screen and (max-width: 576px) {
  .company-logo {
      width: 120px;
  }
  .company-logo img {
      height: 60px;
  }
  .companies-worked-with h3 {
      font-size: 1.8rem;
  }
  .company-logo p {
      font-size: 0.9rem;
  }
}

@media screen and (max-width: 400px) {
  .company-logos {
      gap: 1rem;
  }
  .company-logo {
      width: 100px;
  }
  .company-logo img {
      height: 50px;
  }
  .companies-worked-with h3 {
      font-size: 1.6rem;
  }
  .company-logo p {
      font-size: 0.8rem;
  }
}